@use 'sass:map';

@mixin mat-tree($grey) {
  .mat-tree {
    .mat-tree-node.mat-tree-node-selected {
      background: map.get($grey, 300);
      color: map.get($grey, 'contrast-200');
    }
  }
}
