@use 'sass:map';
@use 'sass:list';

$spacer: 2rem !default;
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  // 8px
  2: $spacer * 0.5,
  // 16px
  3: $spacer * 0.75,
  // 24px
  4: $spacer,
  // 32px
  5: $spacer * 1.25,
  // 40px
  6: $spacer * 1.5,
  // 48px
  7: $spacer * 2,
  // 64px
  8: $spacer * 3,
  // 96px
);
$grid-gutter-width: 1rem;
$enable-negative-margins: true;

@each $key, $spacer in $spacers {
  :root {
    --spacer#{$key}: #{map.get($spacers, $key)};
  }
}

$position-values: (
  0: 0,
  50: 50%,
  100: 100%,
);

$position-values: map.merge($position-values, $spacers);

$table-bg: initial;
$table-color: initial;

$include-column-box-sizing: true !default;
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/variables-dark';
@import 'bootstrap/scss/maps';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/utilities';
@import 'bootstrap/scss/root';
@import 'bootstrap/scss/helpers';
.table {
  border-spacing: 0;
}

$extra-values: (
  0: 0,
  20: 20%,
  80: 80%,
);

$utilities: map.merge(
  $utilities,
  (
    cursor: (
      property: cursor,
      values: auto default pointer wait text move help not-allowed,
    ),
    overflow:
      map.merge(
        map.get($utilities, overflow),
        (
          values: list.append(map.get($utilities, overflow, values), initial),
        )
      ),
    width:
      map.merge(
        map.get($utilities, width),
        (
          responsive: true,
          values: map.merge(map.get($utilities, width, values), $extra-values),
        )
      ),
    height:
      map.merge(
        map.get($utilities, height),
        (
          responsive: true,
          values: map.merge(map.get($utilities, height, values), $extra-values),
        )
      ),
  )
);

@import 'bootstrap/scss/vendor/rfs';
@import 'bootstrap/scss/containers';
@import 'bootstrap/scss/grid';
@import 'bootstrap/scss/images';
@import 'bootstrap/scss/tables';
@import 'bootstrap/scss/utilities/api';
