@mixin mat-form-field($config, $typography) {
  .mat-mdc-form-field {
    margin-bottom: map.get($spacers, 2);
  }

  /* TODO(mdc-migration-verify): Appearance standard does not exist anymore, only fill or outline. */
  .mat-form-field-appearance-standard .mat-mdc-form-field-flex {
    align-items: end;
  }
}
